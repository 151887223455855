// import 'jquery';

// -------------------------------------------------------
// Lazysizes - Lazyload
// -------------------------------------------------------
// import 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// import 'lazysizes/plugins/respimg/ls.respimg';
//import 'lazysizes/plugins/blur-up/ls.blur-up';

// import mmenu-light


// -------------------------------------------------------
// mixitup - Filter
import mixitup from 'mixitup';

// Initialisiere Mixitup
var containerEl = document.querySelector('.container');
var mixer = mixitup(containerEl);



// -------------------------------------------------------



// Smooth ID Scroll
// $(document).ready(function () {
//     $('a[href^="#"]').on('click', function (e) {
//         e.preventDefault();
//         var target = this.hash,
//             $target = $(target);
//         $('html, body').stop().animate({
//             'scrollTop': $target.offset().top
//         }, 900, 'swing', function () {
//             window.location.hash = target;
//         });
//     });
// });


// Sortiment Hover Blending
// $(function () {
//     $('img.hover').each(function () {
//         $(this).wrap('<div class="tint"></div>');
//     });
// });  